// Justified nav links
// -------------------------

@mixin nav-justified {
    width: 100%;

    .nav-item {
        float: none;
    }

    .nav-link {
        text-align: center;
        margin-bottom: 5px;
    }

    > .dropdown .dropdown-menu {
        top: auto;
        left: auto;
    }

    @include media-breakpoint-up(sm) {
        .nav-item {
            display: block;
            width: 1%;
        }
        .nav-link {
            margin-bottom: 0;
        }
    }
}

// Move borders to anchors instead of bottom of list
//
// Mixin for adding on top the shared `.nav-justified` styles for our tabs
@mixin nav-tabs-justified {
    .nav-link {
        // Override margin from .nav-tabs
        margin-right: 0;
        border-radius: $border-radius;
    }

    @include media-breakpoint-up(sm) {
        .nav-link.active,
        .nav-link.active:hover,
        .nav-link.active:focus {
            border-bottom-color: transparent; //$nav-tabs-justified-active-link-border-color;
        }
    }
}
