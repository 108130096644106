/*=========================================================================================
	File Name: search.scss
	Description: Search  functionality.
	----------------------------------------------------------------------------------------
	Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

.navbar-container {
    a.nav-link-search {
        padding: 0;
        display: flex;
        align-items: center;
        span{
            padding-left: 8px;
            font: normal normal normal 10px/17px Poppins;
            color: #666666;
        }
        svg{
            width: 1.15rem;
            height: 1.15rem;
        }
    }

    .search-input {
        width: 0;
        float: left;
        display: none;

        // select
        input {
            width: 0;
            border: none;
            background: none;
            transition: all 0.2s ease-out;
            line-height: 16px;
            padding: 1.1rem 2.6rem;
            &::placeholder {
                font: normal normal normal 10px/17px Poppins;
                color: #666666;
              }
        }

        &.open {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1000;
            width: 100%;
            display: block;
            background: $white;
            border-radius: 0.5rem;

            .search-input-close,
            .search-input-icon {
                display: block;
            }

            // select

            input {
                width: 100%;
                outline: none;
                background: none;
                height: auto;
                box-shadow: none;
                transition: all 0.3s ease-out;
            }
        }

        .search-list {
            max-height: 450px;
            height: auto;
            position: absolute;
            top: 100%;
            left: 0;
            background: $white;
            width: 100%;
            margin-top: 0.5rem;
            padding-left: 0;
            border-radius: $border-radius;
            display: none;
            @media only screen and (min-device-width: 320px) and (max-device-width: 875px) and (orientation: landscape) {
                max-height: 250px;
            }

            &.search-list-bookmark {
                height: auto !important;
                i,
                svg {
                    font-size: 1.2rem;
                    height: 1.2rem;
                    width: 1.2rem;
                }
            }

            &.show {
                display: block;
                min-height: 3rem;
            }

            li {
                a {
                    padding: 0.9rem 1rem;
                    color: $body-color;

                    i,
                    svg[class*='feather feather-'] {
                        height: 1.25rem;
                        width: 1.25rem;
                        font-size: 1.25rem;
                    }
                }

                &:first-child {
                    border-top-left-radius: $border-radius;
                    border-top-right-radius: $border-radius;
                }

                &:last-child {
                    border-bottom-left-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;
                }

                &.auto-suggestion {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    &:hover,
                    &.current_item {
                        background-color: $body-bg;
                    }
                }
            }
        }

        .search-input-icon {
            z-index: 2;
            display: none;
            position: absolute;
            left: 1rem;
            cursor: pointer;
            top: 50%;
            transform: translate(0, -50%);
            i,
            svg {
                height: 1.15rem;
                width: 1.15rem;
            }
        }

        .search-input-close {
            z-index: 1;
            display: none;
            position: absolute;
            right: 1rem;
            cursor: pointer;
            top: 50%;
            transform: translate(0, -50%);
            i,
            svg {
                height: 1.25rem;
                width: 1.25rem;
                font-size: 1.25rem;
            }
        }
    }

    .bookmark-input {
        position: absolute;
        top: 102%;
        width: 25%;
        display: none;
        background: $white;
        border-radius: $border-radius;
        box-shadow: 0 15px 30px 0 rgba($black, 0.11), 0 5px 15px 0 rgba($black, 0.08);

        &.show {
            display: block;
        }

        .bookmark-input-icon {
            position: absolute;
            z-index: 1;
            top: 20px;
            left: 24px;
        }

        input {
            padding: 0.7rem 0.7rem 0.7rem 2.5rem;
            margin: 12px;
            width: calc(100% - 24px); // Reduce width by 10*2
            background: $white;
            border: 1px solid rgba($black, 0.2);

            &:focus {
                border: 1px solid $primary;
            }
        }

        .search-list {
            position: relative; // To consider outer div width
        }
    }

    .bookmark-icon {
        cursor: pointer;
    }

    .dropdown.bookmark-dropdown {
        padding: 1.4rem 0.5rem 1.35rem;

        .dropdown-toggle:after {
            display: none;
        }
    }

    .search-data-title {
        line-height: 1.25rem;
    }
}

.navbar-dark
// .navbar-semi-light
{
    .search-input {
        .input {
            color: #f5f7fa;
            border-radius: 0.5rem;
        }

        &.open {
            .input {
                color: #f5f7fa;
                border: 1px solid #f5f7fa;
            }
        }
    }
}

// navbar-sticky
.navbar-static {
    .navbar-container {
        .search-input {
            &.open {
                border-radius: 0;
            }
        }
    }
}
